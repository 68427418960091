@font-face {
  font-family: "Rubik";
  src: local("Rubik-Regular"),
    url("./fonts/Rubik-VariableFont_wght.ttf") format("truetype");
}


@font-face {
  font-family: "Roboto";
  src: local("Roboto-Regular"),
    url("./fonts/Roboto-Regular.ttf") format("truetype");
}


a:visited,
a {
  color: #4077B0;
}

h5 {
  margin-top: 0px;
  margin-bottom: 10px;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.auth-wrapper {
  padding-top: 200px;
}

.header-offset {
  padding: 15px;
  padding-top: 100px;
}

.App-header {
  background-color: #232941;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-header-tab a:visited,
.App-header-tab a {
  color: #ffffff;
}

.App-header-tab {
  border-bottom: 2px solid transparent
}

.App-header-tab-selected {
  border-bottom: 2px solid white
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.doc-info-tab {
  position: absolute;
  top: 80px;
  right: 0;
  width: '25px';
  height: '25px';
  border: 1px solid #232941;
  background-color: #ffffff;
  border-right: none;
  border-radius: 5px 0px 0px 5px;

}

.doc-info-content {
  position: absolute;
  top: 10px;
  right: 0;
  bottom: 0;
  overflow-y: auto;
  font-size: 9pt;
}

.doc-label {
  font-weight: bold;
}

.doc-content-section .MuiGrid2-direction-xs-row {
  border-top: 1px solid #d4d4d4;
  padding-top: 3px;
  padding-bottom: 3px;
}

.collapsible-hr {
  margin-top: 15px;
  border-top: 1px solid #d4d4d4;
  margin-bottom: 15px;
}

.section-hr {
  margin-top: 15px;
  border-top: 1px solid #d4d4d4;
  margin-bottom: 10px;
}

.form-hr {
  margin-top: 20px;
  border-top: 1px solid #d4d4d4;
  margin-bottom: 15px;
}

.doc-list-header {
  font-weight: bold;
}

.doc-row-odd {
  background-color: #fafafa;
}

.grid-row-warning {
  background-color: #FFF4E5;
}

.pdfViewer .page {
  margin: 1px auto -3px auto !important;
  border: 1px dashed transparent !important;
}

.commentsDialog {
  position: absolute;
  bottom: 90px;
  right: 35px;
  z-index: 999;
  width: 450px;
}

.adjuster-form-data {
  font-size: 12px;
}

.adjuster-form-data .adjuster-form-label {
  font-weight: 700;
  color: #232941;
  min-width: 125px;
}

.adjuster-form-data .adjuster-form-label-lg {
  font-weight: 700;
  color: #232941;
  min-width: 125px;
}

.adjuster-form-data .adjuster-form-data {
  color: #232941;
  min-width: 115px;
}

.adjuster-form-data .adjuster-form-data-lg {
  color: #232941;
  min-width: 160px;
}

.banner-container {
  border: 1px solid #4077B0;
  border-radius: 5px;
  padding: 10px;
  margin-top: 15px;
}

.banner {
  color: #4077B0;
  font-size: 12px;
}

.banner-green {
  background-color: #EDF7ED;
  font-size: 14px;
  font-weight: 400;
  padding: 5px;
  padding-top: 10px;
  padding-bottom: 10px;
  color: #2E7D32
}

.env-stage {
  margin-left: 50px;
  font-weight: 700;
  color: #fa0c0c;
}

.highlight-tag {
  color: #000000DE;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  border: 1px solid rgba(0, 0, 0, .08);
  padding: 3px 16px 3px 16px;
  border-radius: 100px;
  margin-right: 5px;
  background: rgba(0, 0, 0, .08);
  line-height: 18px;
}

.highlights-text-block {
  font-size: 10pt;
}

.secondary {
  color: #4077B0 !important;
}

.demand-title {
  font-size: 34px;
  color: #232941;
  font-family: Rubik;
}

.attachments-table .MuiTableCell-root {
  border-bottom: none;
  padding: 0px;
  padding-top: 3px;
  padding-bottom: 3px;
}

.primary-actions {
  color: rgba(0, 0, 0, 0.56);
}

.MuiDataGrid-viewport,
.MuiDataGrid-row,
.MuiDataGrid-renderingZone {
  max-height: none !important;
}

.MuiDataGrid-cell {
  max-height: none !important;
  overflow: auto;
  white-space: initial !important;
  display: flex !important;
  align-items: center;
}

.MuiDataGrid-root .MuiDataGrid-cell:focus-within {
  outline: none !important;
}

#treatment-summary-header {
  display: flex;
  flex-direction: row;
}

.popoverContent {
  pointer-events: 'auto';
}

.popover {
  pointer-events: 'none';
}

.status-chip.received,
.status-chip.medical_extraction_complete {
  background-color: #1B5E20;
  border: 1px solid #1B5E20;
  color: #ffffff;
}

.status-chip.pending {
  background-color: #ffffff;
  border: 1px solid #9C27B0;
  color: #9C27B0;
}

.status-chip.submitted {
  background-color: #ffffff;
  border: 1px solid #1B5E20;
  color: #1B5E20;
}

.status-chip.approved {
  background-color: #232941;
  border: 1px solid #232941;
  color: #ffffff;
}

.status-chip.rejected {
  background-color: #FFA726;
  border: 1px solid #FFA726;
  color: #232941;
}

.status-chip.waitingapproval {
  background-color: #01579B;
  border: 1px solid #01579B;
  color: #ffffff;
}

.status-chip.bypass_ai_processing,
.status-chip.waitingreview {
  background-color: #E1F5FE;
  border: 1px solid #01579B;
  color: #01579B;
}

.status-chip.archived,
.status-chip.no_medical_bills {
  background-color: #FFFFFF;
  border: 1px solid #BDBDBD;
  color: #232941;
}

.status-chip.awaitingmedicalextraction {
  background-color: #ede7f6;
  border: 1px solid #311b9280;
  color: #311b92;
}

.status-chip.awaitingsubmission,
.status-chip.awaitingprecedentsignoff {
  background-color: #9C27B0;
  border: 1px solid #9C27B0;
  color: #ffffff;
}

.status-chip.emailsubmitted,
.status-chip.faxsubmitted {
  background-color: #2196F3;
  border: 1px solid #2196F3;
}

.status-chip.emailsent,
.status-chip.faxsent,
.status-chip.manuallysent {
  background-color: #01579B;
  border: 1px solid #01579B;
  color: #FFFFFF;
}

.status-chip.emailsendfailure,
.status-chip.faxsendfailure,
.status-chip.faxsubmissionfailed,
.status-chip.validation_failed {
  background-color: #FDEDED;
  border: 1px solid #b71c1c;
  color: #b71c1c;
}

.edit-form-section-title {
  font-family: Roboto;
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: 0px;
  text-align: left;
  margin-top: 10px;
}

.edit-form-section-subtitle {

  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.15000000596046448px;
  text-align: left;
}

.dialog-title {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.breadcrumbs-container {
  padding: 10px;
  padding-right: 0px;
  margin-top: 10px;
}

.breadcrumbs-page-title-container {
  margin-bottom: 10px;
}

.breadcrumbs-page-link {
  text-decoration: none;
  color: #2196F3;
  cursor: pointer;
}

.breadcrumbs-page-active {
  text-decoration: none;
  color: rgba(0, 0, 0, 0.60);
}

.breadcrumbs-link {
  text-decoration: none;
  color: #4077B0;
  cursor: pointer;
}

.breadcrumbs-active {
  text-decoration: none;
  color: rgba(0, 0, 0, 0.87);
}

.pin-label {
  color: rgba(0, 0, 0, .60);
  padding-top: 5px;
}

.dialog-title-blue {
  background-color: #232941;
  color: #ffffff;
}

.add-contact-label {
  color: rgba(0, 0, 0, 0.60);
}

.script-section-title {
  font-weight: 500;
  font-size: 20px;
}

.script-text {
  color: rgba(0, 0, 0, 0.60);
  font-size: 16px;
}

.href {
  color: rgb(79, 149, 246);
  text-decoration: underline;
  cursor: pointer;
}

.customer-button {
  cursor: pointer;
}

.general-info-text {
  font-size: 24px;
  color: rgba(0, 0, 0, 0.60);
}

.selected-carrier-item {
  background-color: #f5f5f5;
}

.carrier-item {
  font-size: 16px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.87);
  cursor: pointer;
}

.carrier-item:hover {
  background-color: #f5f5f5;
}

/* Author Styles */

.author-title {
  color: #232941;
  font-size: 34px;
  font-weight: 300;
}

.author-step-title {
  color: #232941;
  font-size: 24px;
  font-weight: 300;
}

.author-section-title {
  color: #4077B0;
  font-size: 24px;
  font-weight: 400;
}

.author-subsection-title {
  color: #4077B0;
  font-size: 20px;
  font-weight: 400;
}

.author-info-box {
  background-color: #E5F6FD;
  border-radius: 4px;
  border: 1px solid E5F6FD;
}

.highlight {
  background-color: yellow;
}

fieldset {
  border: 0;
}

.admin-handle-right {
  background-color: #E0E0E0;
  width: 3px !important;
  margin-right: 4px;
}

.admin-handle-right:hover,
.admin-handle-right:active {
  background-color: #232941;
  width: 5px !important;
  margin-right: 3px;
  z-index: 1000;
}